import { SetSnackBar } from './SnackbarHelper';

export function DisableFormAutofill(formId: string) {
  var inputs = document.querySelectorAll(`#${formId} input[type=text]`);
  inputs.forEach(input => {
    input.setAttribute('role', 'presentation');
    input.setAttribute('autocomplete', 'off');
  });
}
export function DisableFieldAutofill(id: string) {
  setTimeout(() => {
    const input = document.getElementById(id);
    if (input) {
      input.setAttribute('role', 'presentation');
      input.setAttribute('autocomplete', 'off');
    }
  }, 100);
}

export function CopyToClipboard(data: string) {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      SetSnackBar(`Copied ${data} to clipboard`);
    })
    .catch(() => {
      SetSnackBar('Error copying to clipboard');
    });
}

export function Wait(func: () => any, time: number = 50) {
  setTimeout(() => {
    func();
  }, time);
}
export function OpenCenteredWindow(
  url: string,
  title: string,
  w: number,
  h: number
): Window | null {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft: number =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop: number =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width: number =
    window.innerWidth || document.documentElement.clientWidth || screen.width;
  const height: number =
    window.innerHeight || document.documentElement.clientHeight || screen.height;

  const isFullScreen: boolean = document.fullscreenElement !== null;

  const systemZoom: number = width / window.screen.availWidth;
  let left: number, top: number;

  if (isFullScreen) {
    // Adjust the window size and position for full-screen mode
    left = 0;
    top = 0;
    w = window.screen.width;
    h = window.screen.height;
  } else {
    left = (width - w) / 2 / systemZoom + dualScreenLeft;
    top = (height - h) / 2 / systemZoom + dualScreenTop;
  }

  const newWindow: Window | null = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w}, 
    height=${h}, 
    top=${top}, 
    left=${left}
    `
  );

  if (newWindow && window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
